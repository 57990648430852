import "url-polyfill";
import React, { Component } from "react";
import {
  Card,
  CardContent,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import logo from "../images/certane-logo-small.png";
import checklist from "../images/checklist.svg";
import SubmitButton from "../components/accounts/SubmitButton";
import { Field } from "react-final-form";
import Input from "../components/common/TextField";
import { compose, isEmail, isUrl, required } from "../utils/validators";
import { colors } from "../Theme";
import { Location, WindowLocation } from "@reach/router";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Form from "../components/common/Form";
import { navigate } from "gatsby";
import _ from "lodash";
import PrivateRoute from "../components/auth/PrivateRoute";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 470,
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 50,
      textAlign: "center"
    },
    card: {
      boxShadow: "none"
    },
    logo: {
      width: 83
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 36,
      lineHeight: "45px",
      fontWeight: 200,
      maxWidth: 400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    subtitle: {
      color: theme.palette.primary.main,
      fontSize: 28,
      fontWeight: 300,
      textAlign: "left",
      marginTop: theme.spacing(4)
    },
    details2: {
      fontSize: 16,
      color: colors.blueGrey,
      fontWeight: 600,
      maxWidth: 400,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4)
    },
    details: {
      fontSize: 18
    },
    tasks: {
      textAlign: "left",

      "& li": {
        lineHeight: 2,
        display: "list-item"
      }
    },
    checklistIcon: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4)
    }
  });

const applyToSargonMutation = gql`
  mutation ApplyToSargon($application: Application!) {
    applyToSargon(application: $application)
  }
`;

export class Apply extends Component<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <img src={logo} className={classes.logo} />
        <Typography className={classes.title} paragraph>
          We'd love to hear what you're working on
        </Typography>

        <img src={checklist} className={classes.checklistIcon} />

        <Location>
          {location =>
            this.isUnavailableFeature(location.location) && (
              <Typography paragraph className={classes.details}>
                This feature is only available to Impact clients.
              </Typography>
            )
          }
        </Location>

        <Typography paragraph className={classes.details2}>
          To enquire about access to our production features and APIs, please
          fill in the form below:
        </Typography>

        <Mutation mutation={applyToSargonMutation}>
          {(applyToSargon, { loading }) => (
            <Form
              onSubmit={application =>
                applyToSargon({ variables: { application } })
              }
              successMessage="Application submitted"
              onSuccess={this.onSuccess}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Field
                        name="fullName"
                        component={Input}
                        label="Full Name"
                        fullWidth
                        margin="dense"
                        validate={required("Full Name is required")}
                      />

                      <Field
                        name="company"
                        component={Input}
                        label="Company"
                        fullWidth
                        margin="dense"
                        validate={required("Company is required")}
                      />

                      <Field
                        name="email"
                        component={Input}
                        label="Email"
                        type="email"
                        fullWidth
                        margin="dense"
                        validate={compose(
                          required("Email is required"),
                          isEmail("Must be a valid email address")
                        )}
                      />

                      <Field
                        name="details"
                        component={Input}
                        label="Tell us what you are working on"
                        fullWidth
                        multiline
                        margin="dense"
                        validate={required("This is required")}
                      />
                    </CardContent>
                  </Card>

                  <Typography className={classes.subtitle}>
                    We encourage that you think about the following on your
                    business plan:
                  </Typography>

                  <ul className={classes.tasks}>
                    <Typography component="li">
                      Describe the problem that you're solving
                    </Typography>
                    <Typography component="li">
                      What attracted you to Impact?
                    </Typography>
                    <Typography component="li">
                      Why is your team the best at solving the problem?
                    </Typography>
                    <Typography component="li">
                      How do you plan to distribute and market your fund to
                      customers?
                    </Typography>
                    <Typography component="li">
                      Your plan to build {"&"} operate your fund's online
                      customer experience?
                    </Typography>
                    <Typography component="li">
                      How would you ensure that your fund is secure and
                      compliant?
                    </Typography>
                    <Typography component="li">
                      How much capital do you have or plan to raise?
                    </Typography>
                    <Typography component="li">
                      Where will this capital be invested?
                    </Typography>
                  </ul>
                  <Card className={classes.card}>
                    <CardContent>
                      <Field
                        name="businessPlan"
                        component={Input}
                        label="URL to pitch deck or business plan"
                        fullWidth
                        margin="dense"
                        validate={compose(
                          required("This is required"),
                          isUrl("Must be a valid URL")
                        )}
                      />
                    </CardContent>
                  </Card>

                  <SubmitButton label="Submit" disabled={loading} />
                </form>
              )}
            </Form>
          )}
        </Mutation>
      </div>
    );
  }

  isUnavailableFeature = (location: WindowLocation) =>
    _.get(location.state, "unavailableFeature", false);

  onSuccess = () => navigate("/");
}

const ApplyPage = withStyles(styles)(Apply);

export default () => <PrivateRoute component={ApplyPage} />;
